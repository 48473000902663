import React from "react"
import Container from "../components/Container"
import ContactForm from "../components/ContactForm"
import { HiOutlineMail } from "react-icons/hi"
import { AiOutlinePhone } from "react-icons/ai"
import { IoLocationOutline } from "react-icons/io5"
import { AiOutlineClockCircle } from "react-icons/ai"
import BgImg from "./BgImg"

const ContactComponent = () => (
  <Container
    title="Contact"
    style={{
      background: `#52575a`,
      color: `white`,
    }}
    whitetitle
  >
    <h3 style={{ maxWidth: "100%", textAlign: "left", zIndex: 10 }}>
      Contact us or fill out your information below. Let's get started on your
      dream home today!
    </h3>
    <div
      style={{
        width: "100%",
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "space-around",
        maxWidth: "1100px",
        zIndex: 10,
      }}
    >
      <ContactForm />
      <div className="contact">
        <h5 style={{ padding: "1.5rem", display: "flex" }}>
          <HiOutlineMail size={25} />
          <div style={{ paddingLeft: "1rem" }}>henrygatewood@aol.com</div>
        </h5>
        <h5 style={{ padding: "1.5rem", display: "flex" }}>
          <AiOutlinePhone size={25} />
          <div style={{ paddingLeft: "1rem" }}>(559) 901-6822</div>
        </h5>
        <h5 style={{ padding: "1.5rem", display: "flex" }}>
          <IoLocationOutline size={25} />
          <div style={{ paddingLeft: "1rem" }}>
            1187 N Willow Ave Ste. 103
            <br />
            PMB #88
            <br />
            Clovis, CA 93611
          </div>
        </h5>
        <h5 style={{ padding: "1.5rem", display: "flex" }}>
          <AiOutlineClockCircle size={25} />
          <div style={{ paddingLeft: "1rem" }}>
            Mon-Fri: 10 AM - 5 PM
            <br />
            Sat-Sun: 10 AM - 1 PM
          </div>
        </h5>
      </div>
    </div>
    <BgImg />
  </Container>
)

export default ContactComponent
