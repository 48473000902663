import React from "react"
import Img from "gatsby-image"
import Carousel from "react-bootstrap/Carousel"
import { useStaticQuery, graphql } from "gatsby"

const CarouselComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      slide01: file(relativePath: { eq: "carousel/photo1.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide02: file(relativePath: { eq: "carousel/photo2.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide03: file(relativePath: { eq: "carousel/photo3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide04: file(relativePath: { eq: "carousel/photo4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide05: file(relativePath: { eq: "carousel/photo5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide06: file(relativePath: { eq: "carousel/photo6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide07: file(relativePath: { eq: "carousel/photo7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Carousel style={{ zIndex: "5" }}>
        <Carousel.Item>
          <Img
            className="d-block w-100"
            fluid={data.slide01.childImageSharp.fluid}
            alt="First slide"
            style={{ maxHeight: "60vh" }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <Img
            className="d-block w-100"
            fluid={data.slide02.childImageSharp.fluid}
            alt="Second slide"
            style={{ maxHeight: "60vh" }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <Img
            className="d-block w-100"
            fluid={data.slide03.childImageSharp.fluid}
            alt="Third slide"
            style={{ maxHeight: "60vh" }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <Img
            className="d-block w-100"
            fluid={data.slide04.childImageSharp.fluid}
            alt="Fourth slide"
            style={{ maxHeight: "60vh" }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <Img
            className="d-block w-100"
            fluid={data.slide05.childImageSharp.fluid}
            alt="Fifth slide"
            style={{ maxHeight: "60vh" }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <Img
            className="d-block w-100"
            fluid={data.slide06.childImageSharp.fluid}
            alt="Sixth slide"
            style={{ maxHeight: "60vh" }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <Img
            className="d-block w-100"
            fluid={data.slide07.childImageSharp.fluid}
            alt="Seventh slide"
            style={{ maxHeight: "60vh" }}
          />
        </Carousel.Item>
      </Carousel>
    </>
  )
}

export default CarouselComponent
