import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Services from "../components/Services"
import ContactComponent from "../components/ContactComponent"
import CarouselComponent from "../components/CarouselComponent"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <CarouselComponent />
    <ContactComponent />
  </Layout>
)

export default IndexPage
